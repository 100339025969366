import React from 'react';
import Link from 'gatsby-link';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          html
          frontmatter {
            title
            path
            type
            date
          }
        }
      }
    }
  }
`;
const styleClasses = ['is-success', 'is-danger', 'is-info', 'is-warning'];
const IndexPage = ({ data }) => (
  <Layout>
    <div>
      <Helmet>
        <meta name="google-site-verification" content="69vf5hEKKJ03dvNOSkAVoMmyUUpNx17HRPlrLma6eMs" />
      </Helmet>
      <section
        className="hero is-primary is-medium"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.15'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div
              className="columns"
              css={{
                marginBottom: '2em'
              }}
            >
              <div className="column is-9">
                <h1 className="title is-size-1"><span role="img" aria-label="hand waving emoji">👋</span> Hey There. Nice to meet you. My name is Mike and I make things on the internet.</h1>
                {/* <h2 className="subtitle">I make apps. Pretty good ones too.</h2>
                <p>I've also made a checklist to help with your Hybrid/Cordova/Ionic app security. It's cool, check it out. Maybe it'll help you. Maybe it won't. You'll just have to download it and see.</p>
                <Link className="button is-danger is-raised" to="/hybrid-app-security-checklist/">
                  Get the FREE checklist!
                </Link> */}
              </div>
              {/* <div className="column is-3">
                <div className="box">
                  <div className="content">
                    This is an advertisement. Please disable your ad blocker, it's
                    how I make money to keep this site running and bring you
                    content!
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div
        className="container"
        style={{
          padding: '1em'
        }}
      >
        <section>
          <h2 className="title is-4">Recent Blog Posts</h2>
          <div
            className="columns"
            style={{
              marginBottom: '2em'
            }}
          >
            {data.allMarkdownRemark.edges
              .filter(post => post.node.frontmatter.type === 'post')
              .sort(
                (a, b) =>
                  a.node.frontmatter.date > b.node.frontmatter.date
                    ? -1
                    : a.node.frontmatter.date < b.node.frontmatter.date ? 1 : 0
              )
              .slice(0, 3)
              .map(post => (
                <div key={post.node.id} className="column">
                  <article className="card">
                    <div className="card-image">
                      <Link to={post.node.frontmatter.path}>
                        <figure className={styleClasses[Math.floor(Math.random() * styleClasses.length)] + " image is-4by3 hero"} />
                      </Link>
                    </div>
                    <div className="card-content">
                      <h2 className="title is-5">
                        <Link to={post.node.frontmatter.path}>
                          {post.node.frontmatter.title}
                        </Link>
                      </h2>
                    </div>
                  </article>
                </div>
              ))}
            <div
              className="column"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Link
                className="see-all-link"
                to="/blog"
                css={{
                  fontSize: '2rem',
                  ':hover': {
                    '::after': {
                      transform: 'translate3d(5px, 0, 0)'
                    }
                  },
                  '::after': {
                    content: ' →',
                    marginLeft: '10px',
                    transition: 'transform .3s',
                    transform: 'translate3d(0, 0, 0)',
                    display: 'inline-block'
                  }
                }}
              >
                See All
              </Link>
            </div>
          </div>
        </section>
        {/* <section>
          <h2 className="title is-4">Courses</h2>
          <div
            className="columns"
            style={{
              marginBottom: '2em'
            }}
          >
            <div className="column">
              <article className="card">
                <div className="card-image">
                  <Link to="/courses/angular/custom-components/">
                    <figure className="image is-4by3 hero is-danger is-bold" />
                  </Link>
                </div>
                <div className="card-content">
                  <h1 className="title is-4">
                    <Link to="/courses/angular/custom-components/">
                      Angular Course
                    </Link>
                  </h1>
                  <div className="content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                    <a href="#">#css</a> <a href="#">#responsive</a>
                  </div>
                </div>
              </article>
            </div>
            <div className="column">
              <article className="card">
                <div className="card-image">
                  <Link to="/courses/react-gatsby/intro">
                    <figure className="image is-4by3 hero is-primary is-bold" />
                  </Link>
                </div>
                <div className="card-content">
                  <h1 className="title is-4">
                    <Link to="/courses/react-gatsby/intro">React Course</Link>
                  </h1>
                  <div className="content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                    <a href="#">#css</a> <a href="#">#responsive</a>
                  </div>
                </div>
              </article>
            </div>
            <div className="column">
              <article className="card">
                <div className="card-image">
                  <Link to="/courses/react-gatsby/intro">
                    <figure className="image is-4by3 hero is-info is-bold" />
                  </Link>
                </div>
                <div className="card-content">
                  <h1 className="title is-4">
                    <Link to="/courses/react-gatsby/intro">Intro to Gatsby</Link>
                  </h1>
                  <div className="content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                    <a href="#">#css</a> <a href="#">#responsive</a>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
        <section>
          <h2 className="title is-4">Projects</h2>
          <div
            className="columns"
            style={{
              marginBottom: '2em'
            }}
          >
            <div className="column">
              <article className="card">
                <div className="card-image">
                  <figure className="image is-4by3 hero is-success is-bold" />
                </div>
                <div className="card-content">
                  <h1 className="title is-4">consectetur adipiscing elit</h1>
                  <div className="content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                    <a href="#">#css</a> <a href="#">#responsive</a>
                  </div>
                </div>
              </article>
            </div>
            <div className="column">
              <article className="card">
                <div className="card-image">
                  <figure className="image is-4by3 hero is-bold is-warning" />
                </div>
                <div className="card-content">
                  <h1 className="title is-4">consectetur adipiscing elit</h1>
                  <div className="content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                    <a href="#">#css</a> <a href="#">#responsive</a>
                  </div>
                </div>
              </article>
            </div>
            <div className="column">
              <article className="card">
                <div className="card-image">
                  <Link to="/">
                    <figure className="image is-4by3 hero is-bold is-link" />
                  </Link>
                </div>
                <div className="card-content">
                  <h1 className="title is-4">consectetur adipiscing elit</h1>
                  <div className="content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                    <a href="#">#css</a> <a href="#">#responsive</a>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section> */}
      </div>
    </div>
  </Layout>
);

export default IndexPage;
